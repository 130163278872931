import React from "react";
import { Container, Row, Col } from "react-grid-system";
// Components
import ProgressBar from "../../components/common/ProgressBar";

const skills = [
    {
        title: "Ilmu Hukum Bisnis dan industri",
        percent: 80,
    },
    {
        title: "Kemampuan Seni",
        percent: 70,
    },
    {
        title: "Broadcasting",
        percent: 70,
    },
    {
        title: "Javascript Fundamental",
        percent: 80,
    },
    {
        title: "Backend Framework (Node Js, Express, dll)",
        percent: 80,
    },
    {
        title: "Database (Mongodb)",
        percent: 75,
    },
    {
        title: "Database (Mysql)",
        percent: 70,
    },
    {
        title: "Frontend WEB Framework (Vue, React JS)",
        percent: 80,
    },
    {
        title: "Hibrid Mobile (React Native)",
        percent: 80,
    },
    {
        title: "Hibrid Mobile (Flutter)",
        percent: 70,
    },
    {
        title: "Mobile Android (Java & Kotlin)",
        percent: 60,
    },
    {
        title: "Mobile IOS (swif)",
        percent: 30,
    },
    {
        title: "Phyton",
        percent: 30,
    },
    {
        title: "Other",
        percent: 99,
    },
];
const education = [
    {
        title: "Universitas Trilogi <Status Tidak Selesai>",
        subtitle: "Manajemen Bisnis | Jakarta - Indonesia",
        description: "",
        date: "(2016-2018)",
        icon: "ti-crown",
    },
    {
        title: "Universitas Trisakti",
        subtitle: "Hukum Bisnis dan Industri | Jakarta - Indonesia",
        description: "",
        date: "(2016-2018)",
        icon: "ti-crown",
    },
    {
        title: "SMU N 42 Jakarta",
        subtitle: "Kejuruan IPS",
        description: "",
        date: "(2016-2018)",
        icon: "ti-cup",
    },
    {
        title: "SMP N 214",
        subtitle: "",
        description: "",
        date: "(2016-2018)",
        icon: "ti-ruler-alt-2",
    },
    {
        title: "SD Tugu Ibu",
        subtitle: "",
        description: "",
        date: "(2016-2018)",
        icon: "ti-ruler-alt-2",
    },
];
const experience = [
    {
        title: "SMDV",
        subtitle: "Senior Developer | Jakarta - Indonesia",
        description: "Ditugaskan sebagai Developer di salah satu anak perusahaan Sinarmas (Sinarmas Agri).",
        date: "(2020-2021)",
        icon: "ti-pencil-alt",
    },
    {
        title: "PHINCON",
        subtitle: "Developer",
        description: "Ditugaskan untuk berada di Klien, sebagai Fullstack Developer (Telkomsel).",
        date: "(2018-2020)",
        icon: "ti-heart",
    },
    {
        title: "Efunding",
        subtitle: "Developer",
        description: "Sebagai Developer untuk mengembangkan Aplikasi Mobile | Web | dan API untuk perusahaan.",
        date: "(2018-2018)",
        icon: "ti-paint-bucket",
    },
    {
        title: "PT Perdana Prima Bhakti Mandiri",
        subtitle: "Team Lead Inventaris Aset",
        description: "Sebagai Team Lead untuk inventaris aset di cabang cabang Bank Mandiri, di Jakarta.",
        date: "(2015-2015)",
        icon: "ti-paint-bucket",
    },
    {
        title: "PT Energi Duta Utama",
        subtitle: "Administrasi Sistem Informasi",
        description: "Sebagai Sekretaris untuk Divisi Sistem Informasi pada Perusahaan Klien (PT Indonesia Power).",
        date: "(2012-2013)",
        icon: "ti-paint-bucket",
    },
    {
        title: "Yayasan Mekar Indah",
        subtitle: "Legal Konsultan",
        description: "Sebagai Legal Konsultan di Yayasan Mekar Indah.",
        date: "(2011-2012)",
        icon: "ti-paint-bucket",
    }
];

function Resume() {
    return (
        <section className="section section-resume section-resume-1" style={{ backgroundColor: 'grey' }}>
            <div className="display-spacing">
                <header className="el-heading el-heading-center">
                    <div className="el-icon">
                        <h2 className="el-icon-title">
                            <span>RESUME</span>
                        </h2>
                    </div>
                    <h3>Pendidikan & Pengalaman</h3>
                    <div className="divider divider-1-reverse divider-1-1"></div>
                    <div className="divider divider-1-reverse divider-1-2"></div>
                </header>
                <Container className="mb-spacing">
                    <Row>
                        <Col md={6}>
                            <div className="resume-row">
                                <h4 className="resume-title">Pendidikan</h4>
                                <ul>
                                    {education.map((item, index) => (
                                        <li key={index}>
                                            <div className="resume-item">
                                                <div className="resume-head">
                                                    <div className="el-icon">
                                                        <span className={`el-icon-icon ${item.icon}`}></span>
                                                    </div>
                                                    <h5>{item.title}</h5>
                                                    <h6>{item.subtitle}</h6>
                                                </div>
                                                <div className="resume-body">
                                                    <p>{item.description}</p>
                                                    {/* <p>Nulla consequat massa quis enim. Donec pede justo, fringilla vel, aliquet nec, vulputate eget, arcu. In enim justo.</p> */}
                                                </div>
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className="resume-row">
                                <h4 className="resume-title">Pengalaman</h4>
                                <ul>
                                    {experience.map((item, index) => (
                                        <li key={index}>
                                            <div className="resume-item">
                                                <div className="resume-head">
                                                    <div className="el-icon">
                                                        <span className={`el-icon-icon ${item.icon}`}></span>
                                                    </div>
                                                    <h5>{item.title}</h5>
                                                    <h6>
                                                        {item.subtitle} <small>{item.date}</small>
                                                    </h6>
                                                </div>
                                                <div className="resume-body">
                                                    <p>{item.description}</p>
                                                </div>
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </Col>
                    </Row>
                </Container>
                <Container>
                    <Row>
                        {skills.map((item, index) => (
                            <Col key={index} sm={6} xl={4}>
                                <div className="skills-item">
                                    <ProgressBar label={item.title} percent={item.percent} />
                                </div>
                            </Col>
                        ))}
                    </Row>
                </Container>
            </div>
        </section>
    );
}

export default Resume;
